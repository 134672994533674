import React from 'react';

import * as servicesStyles from './services.module.css';

export const Services = () => {
  return (
    <ul className={servicesStyles.services}>
      <li>Single family homes</li>
      <li>Semi-detached dwellings/half duplexes</li>
      <li>Duplexes, 3 & 4 unit dwellings</li>
      <li>Homes with income suites</li>
      <li>Rural residential properties/acreages</li>
      <li>Condominiums, apartments, and townhouses</li>
      <li>Quarter share/time share/partial and vacation properties</li>
      <li>Vacant land</li>
    </ul>
  );
};
