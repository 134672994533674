import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { useVisibilityHook } from 'react-observer-api';

import { Areas } from '../components/areas';
import { Content } from '../components/content';
import { Fold } from '../components/fold';
import { Hero } from '../components/hero';
import * as imageStyles from '../components/image.module.css';
import { Layout } from '../components/layout';
import { Map } from '../components/map';
import { Services } from '../components/services';
import {
  aNoteFromOurFounder,
  propertyTypes,
  whatWeOffer,
  whereWeWork,
} from '../content';

const ServicesPage = () => {
  const { setElement, isVisible } = useVisibilityHook({
    threshold: 0.25,
  });
  return (
    <Layout
      title="Bridge & Associates Appraisals Ltd.: Services and service areas"
      description="The appraisal services and regions of Vancouver Island served by Bridge & Associates Appraisals Ltd."
    >
      {({ setIsMenuOpen }) => (
        <>
          <Hero
            setIsMenuOpen={setIsMenuOpen}
            slogan="The services we provide"
            image={
              <StaticImage
                loading="eager"
                alt="A large, grey home seen from the backyard"
                layout="fullWidth"
                aspectRatio={1}
                className={imageStyles.background}
                src={'../images/outdoor.jpg'}
              />
            }
          >
            <Content content={whatWeOffer} withPaddingTop withPaddingBottom>
              <Services />
              <StaticImage
                alt="A white kitchen, decorated with with modern furnishings"
                className={imageStyles.inlineImage}
                aspectRatio={16 / 9}
                src={'../images/second_indoor.jpg'}
              />
            </Content>
          </Hero>
          <Fold>
            <Content content={propertyTypes} withPaddingTop opaque>
              <section className={imageStyles.mapContainer} ref={setElement}>
                {isVisible && <Map />}
              </section>
            </Content>
            <Content
              content={whereWeWork}
              withPaddingTop
              opaque
              anchor="where-we-work"
            >
              <Areas />
              <StaticImage
                alt="A small island covered in trees"
                aspectRatio={16 / 9}
                className={imageStyles.inlineImage}
                src={'../images/vancouver_island.jpg'}
              />
            </Content>
            <Content
              content={aNoteFromOurFounder}
              withPaddingTop
              withPaddingBottom
              opaque
            />
          </Fold>
        </>
      )}
    </Layout>
  );
};

export default ServicesPage;
