import React from 'react';

import * as areasStyles from './areas.module.css';

export const Areas = () => {
  return (
    <section className={areasStyles.areasContainer}>
      <ul className={areasStyles.areas}>
        <li>Campbell River</li>
        <li>Comox</li>
        <li>Courtenay</li>
        <li>Cumberland</li>
        <li>Gold River</li>
        <li>Lantzville</li>
        <li>Mt. Washington</li>
        <li>Nanaimo</li>
      </ul>
      <ul className={areasStyles.secondAreas}>
        <li>Parksville</li>
        <li>Port Alberni</li>
        <li>Port Alice</li>
        <li>Port McNeill</li>
        <li>Port Hardy</li>
        <li>Qualicum Beach</li>
        <li>Sayward</li>
        <li>Tofino</li>
        <li>Uclulet</li>
      </ul>
    </section>
  );
};
