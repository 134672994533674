import GoogleMapReact from 'google-map-react';
import React from 'react';

import * as mapStyles from './map.module.css';

const color = 'rgb(1, 50, 96)';

const path = [
  { lat: 50.7207, lng: -127.4969 },
  { lat: 50.5901, lng: -127.0848 },
  { lat: 50.3837, lng: -125.9602 },
  { lat: 50.0331, lng: -125.2733 },
  { lat: 49.6735, lng: -124.9283 },
  { lat: 49.3482, lng: -124.4428 },
  { lat: 49.3193, lng: -124.3136 },
  { lat: 49.1659, lng: -123.9401 },
  { lat: 49.153, lng: -125.9066 },
  { lat: 49.7782, lng: -126.0496 },
  { lat: 50.7207, lng: -127.4969 },
];

export const Map = () => {
  return (
    <section className={mapStyles.map}>
      <GoogleMapReact
        yesIWantToUseGoogleMapApiInternals
        bootstrapURLKeys={{
          key: `${process.env.GATSBY_GOOGLE_MAPS_API_KEY}`,
        }}
        defaultCenter={{
          lat: 49.8383,
          lng: -125.6185,
        }}
        defaultZoom={7}
        options={{
          zoomControl: false,
          fullscreenControl: false,
        }}
        onGoogleApiLoaded={({ maps, map }) => {
          const serviceArea = new maps.Polygon({
            path: path,
            geodesic: true,
            strokeColor: color,
            strokeOpacity: 1,
            strokeWeight: 3,
            fillColor: color,
          });

          serviceArea.setMap(map);
        }}
      />
    </section>
  );
};
